<template>
    <div class="report-detail-container">
      <section class="report-detail-body">
        <div class="report-detail-header">
          <span style="font-size:16px;">报告日期: {{data.bgrq}}</span>
          <div><img src="@/assets/img/logo-new.png" height="40"></div>
        </div>
        <p class="report-detail-title">训练记录报表</p>
        <p class="report-detail-title title-mini">Stage physiological behavior report</p>
        <div class="user-info-panel">
          <div class="user-info">
            <span class="user-info-label">姓名</span>
            <span class="user-info-text">{{data.jbxx.xm}}</span>
          </div>
          <div class="user-info">
            <span class="user-info-label">性别</span>
            <span class="user-info-text">{{data.jbxx.xb}}</span>
          </div>
          <div class="user-info">
            <span class="user-info-label">年龄</span>
            <span class="user-info-text">{{data.jbxx.nl}}</span>
          </div>
          <div class="user-info">
            <span class="user-info-label">门店</span>
            <span class="user-info-text">{{data.jbxx.md}}</span>
          </div>
        </div>
        <div class="report-graph-panel">
          <section class="report-graph-section" v-for="(item, index) in data.list" :key="index">
            <div class="report-graph-title">训练记录</div>
            <div class="report-graph-content">
              <div class="report-graph" :ref="'graph' + index"></div>
              <p style="text-align:center;color:#fdad00;">{{item.time_area}}</p>
              <table class="report-table">
                <tr>
                  <td class="color-yellow">训练时长</td>
                  <td>{{item.sportTime}}min</td>
                  <td class="color-pink">消耗能量</td>
                  <td>{{item.calories}}kcal</td>
                </tr>
                <tr>
                  <td class="color-yellow">平均心率</td>
                  <td>{{item.avgHeart}}</td>
                  <td class="color-pink">最大心率</td>
                  <td>{{item.maxHeart}}</td>
                </tr>
              </table>
            </div>
          </section>
        </div>
        <table class="report-table">
          <tr>
            <td class="color-yellow">平均训练时长</td>
            <td class="color-blue">最大心率</td>
            <td class="color-pink">平均消耗能量</td>
          </tr>
          <tr>
            <td>{{data.sportTime}}min</td>
            <td>{{data.maxHeart}}</td>
            <td>{{data.calories}}kcal</td>
          </tr>
        </table>
        <p class="report-title">专家解读</p>
        <Input
          v-model="jdnr"
          type="textarea"
          :rows="5"
          placeholder="输入解读内容..."
          v-if="data.jdzt === '未解读'"></Input>
        <div v-else v-html="replaceBreak(data.jdnr)" class="content-panel"></div>
        <Form :label-width="60" class="report-person">
          <FormItem label="咨询师: ">
            <span>{{data.zxs}}</span>
        </FormItem>
        </Form>
      </section>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="showModal = true">下载打印</Button>
        <Button type="warning" size="large" @click="completeReport" v-if="data.jdzt === '未解读'">完成报告</Button>
        <Button type="warning" size="large" @click="handleSubmit" v-else>手动提交报告</Button>
      </div>
      <Modal
        v-model="showModal"
        :loading="loading"
        @on-ok="exportPDF"
        class-name="vertical-center-modal"
        :mask-closable="false">
        <p style="text-align:center;padding:15px;">确定导出训练记录报表吗?</p>
      </Modal>
    </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
import echarts from 'echarts';
import JsPDF from 'jspdf';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';

export default {
	data() {
		return {
			data: {
				jbxx: {},
			},
			jdnr: '',
			loading: true,
			showModal: false,
		};
	},
	mounted() {
		const id = this.$route.params.train_id;
		MemberService.trainReportDetail({ id }).then((data) => {
			this.data = data;
			this.jdnr = data.jdnr;
			this.$nextTick(() => {
				const basis = 220 - this.data.jbxx.nl;
				const low = basis * 0.6;
				const high = basis * 0.8;
				data.list.forEach((item, index) => {
					const dateData = [];
					const highData = [];
					const lowData = [];
					const split1 = item.time_area.split('---');
					const split2 = split1[0].split(' ');
					const beginTime = this.getTimeMinutes(split2[1]);
					const endTime = this.getTimeMinutes(split1[1]);
					const gap = (endTime - beginTime) / item.heartList.length;
					for (let i = 0; i < item.heartList.length; i++) {
						dateData.push(this.getTimeStr(beginTime + Math.round(i * gap)));
						highData.push(low);
						lowData.push(high - low);
					}
					echarts.init(this.$refs[`graph${index}`][0]).setOption({
						backgroundColor: '#f1f2f7',
						color: ['#5886e6', '#f3e1e7', '#f3e1e7'],
						xAxis: {
							name: '时间',
							type: 'category',
							data: dateData,
							boundaryGap: false,
						},
						yAxis: {
							name: '心率',
							type: 'value',
						},
						series: [
							{
								name: '心率',
								data: item.heartList,
								type: 'line',
								symbol: 'none',
								smooth: true,
							},
							{
								name: 'high',
								data: highData,
								type: 'line',
								stack: 'total',
								areaStyle: {
									color: 'transparent',
								},
								symbol: 'none',
							},
							{
								name: 'low',
								data: lowData,
								type: 'line',
								stack: 'total',
								areaStyle: {
									color: '#f3e1e7',
								},
								symbol: 'none',
							},
						],
					});
				});
			});
		});
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	methods: {
		handleSubmit() {
			this.$router.push({ name: 'memberReportAdd' });
		},
		completeReport() {
			if (!this.jdnr) {
				this.$Message.warning('请输入解读内容');
				return;
			}
			const params = {
				member_id: this.memberId,
				id: this.$route.params.train_id,
				jdnr: this.jdnr,
			};
			MemberService.makeTrainReport(params).then(() => {
				this.$Message.success('操作成功');
				this.data.jdzt = '已解读';
			});
		},
		exportPDF() {
			if (this.data.jdzt === '未解读') {
				this.$Message.warning('请先完成报告');
				return;
			}
			const self = this;
			const printTarget = document.querySelector('.report-detail-body');
			if (printTarget) {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
				setTimeout(() => {
					const pdf = new JsPDF({ compress: true, unit: 'pt' });
					html2canvas(printTarget).then(function (canvas) {
						const contentWidth = canvas.width;
						const contentHeight = canvas.height;
						const imgWidth = 555.28;
						const pageMargin = 20;
						const pageData = canvas.toDataURL('image/png', 1.0);

						const imgHeight = (imgWidth / contentWidth) * contentHeight;
						pdf.addImage(pageData, 'png', pageMargin, 0, imgWidth, imgHeight);

						const time = dayjs().format('YYYYMMDD');
						const fileName = `${self.data.jbxx.xm}${time}训练记录报表.pdf`;
						const formData = new FormData();
						formData.append('photo', pdf.output('blob'));
						formData.append('check', 1);
						formData.append('name', fileName);
						MemberService.uploadReport(formData).then((data) => {
							MemberService.addReport({
								member_id: self.$route.params.member_id,
								type: 2,
								bgzl: 42,
								check_date: data.check_date,
								photo_id: data.id,
								bgms: '出具报告自动上传',
								obj_id: 'XL-' + self.$route.params.train_id,
							})
								.then(() => {
									self.$Message.success('转存成功');
								})
								.catch(() => {
									self.$Message.success('转存失败，请手动上传');
								});
						});

						pdf.save(fileName);
						self.showModal = false;
					});
				}, 667);
			} else {
				this.$Message.error('无法生成报告，请稍后重试');
			}
		},
		getTimeMinutes(val) {
			const valArr = val.split(':');
			return +valArr[0] * 60 + +valArr[1];
		},
		getTimeStr(val) {
			return `${Math.floor(val / 60)}:${val % 60}`;
		},
		replaceBreak(val) {
			return val ? val.replace(/\n/g, '<br>') : '';
		},
	},
};
</script>

<style lang="less" scoped>
.report-detail-container {
  background-color: #fff;
  padding: 20px;
}
.report-detail-body {
  width: 920px;
  height: 1380px;
  margin: 0 auto;
}
.report-detail-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.report-detail-title {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #5889e0;
}
.title-mini {
  color: #ccc;
  font-size: 14px;
}
.report-graph-panel {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
}
.report-graph-section {
  flex: 1 0 49%;
  margin-bottom: 10px;
  &:nth-child(even) {
    margin-left: 5px;
  }
}
.report-graph-title {
  background-color: #4472c4;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 5px 0;
}
.user-info-panel {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  padding-top: 20px;
  border-top: #000 solid 2px;
}
.user-info-text {
  padding: 5px 25px;
  border-bottom: #000 solid 1px;
  margin-left: 5px;
}
.report-table {
  width: 100%;
  text-align: center;
  table-layout: fixed;
  margin-top: 15px;
  td {
    border: 1px #d0d0d0 solid;
    padding: 6px;
    &.color-yellow {
      background-color: #ffecae;
    }
    &.color-pink {
      background-color: #ffcfcf;
    }
    &.color-blue {
      background-color: #7cdef9;
    }
  }
}
.report-graph-content {
  box-shadow: 0 1px 4px#d0d0d0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 10px 10px;
}
.report-graph {
  height: 240px;
}
.report-title {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background-color: #0494b9;
  padding: 5px 0;
}
.report-person {
  margin-top: 15px;
}
.content-panel {
  min-height: 120px;
  border: 1px solid #d0d0d0;
  padding: 10px;
}
</style>
